<!--
 * @Description: 意见反馈
 * @Author: huacong
 * @Date: 2021-08-04 15:25:16
 * @LastEditTime: 2021-08-05 13:21:41
 * @LastEditors: huacong
-->
<template>
  <!-- 消息管理 -->
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="24">
          <div class="info-card tab-readNum top-10">
            <el-table :data="unreadTable" style="width: 100%">
              <el-table-column prop="id" label="序号" width="120">
              </el-table-column>
              <el-table-column
                prop="type_text"
                label="反馈类型"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="content"
                label="建议内容"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column prop="is_sms_text" label="反馈状态">
              </el-table-column>
              <el-table-column prop="create_time" label="反馈时间">
              </el-table-column>
              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                hide-on-single-page
                @size-change="SizeChange"
                @current-change="CurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      activeName: "first",
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      unreadTable: [],
      readTable: [],
      lockState: "1",
      readnum: 0,
      unreadnum: 0,
    };
  },
  methods: {
    SizeChange(data) {
      this.pagesize = data;
      this.currentPage = 1;
      this.unreadPost();
    },
    CurrentChange(data) {
      this.currentPage = data;
      this.unreadPost();
    },
    unreadPost() {
      let info = {
        page: this.currentPage,
        limit: this.pagesize,
      };
      this.$http.post("index/index/getfeeback", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.unreadTable = res.data.resultData.list;
        }
      });
    },
  },
  mounted() {
    this.unreadPost();
  },
};
</script>
<style scope>
.talentIndex {
  padding-top: 24px;
}
.tab-readNum {
  position: relative;
}
.readnum {
  position: absolute;
  top: 40px;
  left: 70px;
  z-index: 3;
}
.unreadnum {
  position: absolute;
  top: 40px;
  left: 166px;
  z-index: 3;
}
.top-10 {
  padding-top: 10px;
}
</style>

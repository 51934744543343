<!--
 * @Description: 企业用户个人反馈
 * @Author: huacong
 * @Date: 2021-08-05 11:21:27
 * @LastEditTime: 2021-08-05 13:29:12
 * @LastEditors: huacong
-->
<template>
  <!-- 消息管理 -->
  <div class="talentBg">
    <section>
      <feeback></feeback>
    </section>
  </div>
</template>
<script>
import feeback from "@/components/myfeeback/myfeeback.vue";
export default {
  components: {
    feeback,
  },
};
</script>
<style scope>
.talentIndex {
  padding-top: 24px;
}
.tab-readNum {
  position: relative;
}
.readnum {
  position: absolute;
  top: 40px;
  left: 70px;
  z-index: 3;
}
.unreadnum {
  position: absolute;
  top: 40px;
  left: 166px;
  z-index: 3;
}
.top-10 {
  padding-top: 10px;
}
</style>
